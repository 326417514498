import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { ContentfulSettings } from '../contentful';
import OrderConfirmation from '../components/order-confirmation/order-confirmation';
import { Helmet } from 'react-helmet';

interface OrderConfirmationPageQueryProps {
  contentfulSettings: ContentfulSettings;
}
type OrderConfirmationPageType = PageProps<OrderConfirmationPageQueryProps>;
const OrderConfirmationPage = ({ data }: OrderConfirmationPageType) => {
  return (
    <>
      <Helmet
        title={data.contentfulSettings.ecomCommon.orderConfirmedPage.title}
      />
      <OrderConfirmation
        ecomCommon={data.contentfulSettings.ecomCommon}
        orderConfirmedPage={
          data.contentfulSettings.ecomCommon.orderConfirmedPage
        }
      ></OrderConfirmation>
    </>
  );
};

export const data = graphql`
  query {
    contentfulSettings {
      ecomCommon {
        ...EcomCommon
      }
    }
  }
`;

export default OrderConfirmationPage;
