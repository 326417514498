import { Box, Typography, Button } from '@mui/material';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ContentfulEcomCommon,
  ContentfulOrderConfirmedPage,
} from '../../contentful';
import { useAppDispatch } from '../../hooks';
import { RootState } from '../../store';
import { CartItem } from '../../store/interfaces';
import {
  OrderApiStatus,
  setOrderStateIdle,
  updateApiUserCart,
} from '../../store/slices';

const mapStateToProps = (state: RootState) => {
  return { orderStatusFromStore: state.checkout.orderStatus };
};

interface OrderConfirmationProps {
  orderStatusFromStore: OrderApiStatus;
  orderConfirmedPage: ContentfulOrderConfirmedPage;
  ecomCommon: ContentfulEcomCommon;
}
const OrderConfirmation = ({
  orderStatusFromStore,
  orderConfirmedPage,
  ecomCommon,
}: OrderConfirmationProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (orderStatusFromStore === 'IDLE') {
      navigate('/');
    } else if (orderStatusFromStore === 'DONE') {
      const dummyCartItem: CartItem = {
        articleNumber: '',
        articleQuantityAndAreaExplanation: '',
        articleTitle: '',
        quantity: 0,
        articleSalesUnit: '',
      };
      dispatch(
        updateApiUserCart({
          cartItem: dummyCartItem,
          operation: 'EMPTY',
        }),
      );

      return () => {
        dispatch(setOrderStateIdle());
      };
    }
  }, [dispatch, orderStatusFromStore]);

  const navigateBack = () => {
    navigate('/');
  };

  return (
    <>
      {orderStatusFromStore === 'DONE' && (
        <Box sx={{ my: 5, textAlign: 'center' }}>
          {orderConfirmedPage.icon?.file.url && (
            <img
              src={orderConfirmedPage.icon.file.url}
              alt={orderConfirmedPage.title}
            />
          )}
          <Typography variant="h2" component="h2">
            {orderConfirmedPage.title}
          </Typography>
          <Button
            onClick={navigateBack}
            variant="contained"
            size="large"
            sx={{ mt: 3 }}
          >
            {ecomCommon.continueShopping}
          </Button>
        </Box>
      )}
    </>
  );
};

export default connect(mapStateToProps)(OrderConfirmation);
